import i18n from './i18n';
import axios, {AxiosError} from 'axios';
import {SignUpFields} from "../scenes/SignUp/models/SignUpFields";
import AlertMessage, {Severity} from "../models/AlertMessage";
import ApiResponse from "../models/ApiResponse";
import {CertificateFilePresenterInput} from "../models/CertificateFilePresenterInput";

import betterflyPng from '../res/img/available-tasks/betterfly.png';
import dominoPng from '../res/img/available-tasks/domino.png';
import falabellaDayPng from '../res/img/available-tasks/falabella-diurno.png';
import falabellaNightPng from '../res/img/available-tasks/falabella-nocturno.png';
import forkPng from '../res/img/available-tasks/fork.png';
import jokrPng from '../res/img/available-tasks/jokr.png';
import rosenPng from '../res/img/available-tasks/rosen.png';
import starkenPng from '../res/img/available-tasks/starken.png';
import moment from "moment";
import {DocumentValidationType} from "../models/DocumentValidationType";
import BffApiResponse from "../models/BffApiResponse";
import { Redirect } from 'react-router-dom';

export const MAIN_COLOR = '#CB4089';
const EN_US = 'en_US';
const ES_CO = 'es_CO';
const ES_CL = 'es_CL';
const ES_MX = 'es_MX';
const ES_PE = 'es_PE';
const ES_US = 'es';

let initDate = moment().startOf('isoWeek');

//TODO: por borrar
export const TASK_FAKE_REPOSITORY: any[] = [
  {
    id: 1,
    address: 'Av. Américo Vespucio #7501',
    company: 'Starken',
    date: initDate.clone().add(1, 'days'),
    image: starkenPng,
    status: 'active',
    time: '(07:00 - 13:00 hrs)',
    title: 'Starken - La Florida',
    value: '2.200',
    checkIn: false,
    checkOut: false,
    assigned: true,
  },
  {
    id: 2,
    address: 'Av. La Perla #8521',
    company: 'Starken',
    date:  initDate.clone().add(1, 'days'),
    image: starkenPng,
    status: 'pending',
    time: '(07:00 - 13:00 hrs)',
    title: 'Starken - La Perla',
    value: '2.200',
    checkIn: false,
    checkOut: false,
    assigned: false,
  },
  {
    id: 3,
    address: 'Av. Américo Vespucio #7500',
    company: 'Starken',
    date: initDate.clone().add(4, 'days'),
    image: starkenPng,
    status: 'assigned',
    time: '(07:00 - 13:00 hrs)',
    title: 'Starken - La Florida',
    value: '2.200',
    checkIn: false,
    checkOut: false,
    assigned: false,
  },
  {
    id: 4,
    address: 'Av. Panamá Maracaná #1231',
    company: 'Starken',
    date: initDate.clone().add(4, 'days'),
    image: starkenPng,
    status: 'assigned',
    time: '(06:00 - 11:00 hrs)',
    title: 'Starken - La Florida',
    value: '2.300',
    checkIn: false,
    checkOut: false,
    assigned: true,
  }
];

//TODO: por borrar
export const BRAND_LIST: any = [
  {
    id: 1,
    imgUrl: betterflyPng,
    title: '/betterfly',
    country: 'CL'
  },
  {
    id: 2,
    imgUrl: dominoPng,
    title: '/domino',
    country: 'CL'
  },
  {
    id: 3,
    imgUrl: forkPng,
    title: '/fork',
    country: 'CL'
  },
  {
    id: 4,
    imgUrl: rosenPng,
    title: '/rosen',
    country: 'CL'
  },
  {
    id: 5,
    imgUrl: starkenPng,
    title: '/starken',
    country: 'CL',
  },
  {
    id: 5,
    imgUrl: jokrPng,
    title: '/jokr',
    country: 'CO'
  },
  {
    id: 6,
    imgUrl: falabellaDayPng,
    title: '/falabella-day',
    country: 'PE'
  },
  {
    id: 7,
    imgUrl: falabellaNightPng,
    title: '/falabella-night',
    country: 'PE'
  },
  {
    id: 8,
    imgUrl: falabellaDayPng,
    title: '/falabella-day',
    country: 'PE'
  },
  {
    id: 9,
    imgUrl: falabellaNightPng,
    title: '/falabella-night',
    country: 'PE'
  },
];

export const AVAILABLES_COUNTRIES = [
  {
    name: 'Chile',
    iso: 'es_CL',
  },
  {
    name: 'Peru',
    iso: 'es_PE',
  },
  {
    name: 'Colombia',
    iso: 'es_CO',
  },
  {
    name: 'United States',
    iso: 'en_US',
    extra: 'es_US',
  },
  {
    name: 'Mexico',
    iso: 'es_MX',
  },
  {
    name: 'Others',
    iso: 'none',
  },
]

const DOCUMENT_TYPES_CO: DocumentValidationType[] = [
  // {
  //   default: true,
  //   text: 'Cédula de ciudadanía',
  //   value: 'cc',
  // },
  // {
  //   value: 'ce',
  //   text: 'Cédula extranjería',
  //   default: false
  // },
];

const DOCUMENT_TYPES_PE: DocumentValidationType[] = [
  {
    value: 'DNI',
    text: 'DNI',
    default: true
  },
  {
    value: 'RUC',
    text: 'RUC',
    default: false
  },
  {
    value: 'CE',
    text: 'Carnet de extranjería',
    default: false
  },
  {
    value: 'PASSPORT',
    text: 'Pasaporte',
    default: false
  },
];

const DOCUMENT_TYPES_CL: DocumentValidationType[] = [
  {
    value: 'RUT',
    text: 'RUT',
    default: true
  }
];

const DOCUMENT_TYPES_US: DocumentValidationType[] = [
  {
    value: 'PASSPORT',
    text: 'Passport Card',
    default: true
  }
];

const getCountryLanguage = (country: any) => {
  switch (country) {
    case 'es_PE':
    case 'PE':
      return ES_PE;
    case 'es_MX':
    case 'MX':
      return ES_MX;
    case 'es_CO':
    case 'CO':
      return ES_CO;
    case 'en_US':
    case 'US':
      return EN_US;
    case 'none':
    case 'es_US':
      return ES_US;
    default:
    case 'es_CL':
    case 'CL':
      return ES_CL;
  }
};

export const capitalize = (word: string = '') => {
  if(word.length < 1) return '';
  return word[0].toUpperCase() + word.substring(1).toLowerCase();
};

export const titleCase = (title: string = '') => {
  return title
          .toLowerCase()
          .split(/ /g).map(word =>
              `${word.substring(0,1).toUpperCase()}${word.substring(1)}`)
          .join(" ");
}

export const getDocumentTypes = (country: string): DocumentValidationType[] => {
  switch (country)
  {
    case 'PE':
      return DOCUMENT_TYPES_PE;
    case 'CO':
      return DOCUMENT_TYPES_CO;
    case 'CL':
      return DOCUMENT_TYPES_CL;
    case 'US':
      return DOCUMENT_TYPES_US;
    default:
      throw Error('Country not supported.');
  }
};

export interface RequirementsDocumentValidationForm {
  blackList: string[],
};

export const getRequirementsDocumentValidationForm= (country: string): RequirementsDocumentValidationForm => {
  switch (country) {
    case 'CL':
      return {
        blackList: [ 'documentType' ]
      }
    default:
      return {
        blackList: []
      }
  }
};

export const getDocumentNumberProperties = (country: string, documentType: string): any => {
  let response: any = {};

  switch (country) {
    case 'PE':
      switch (documentType) {
        case 'DNI':
          response['minLength'] = 8;
          response['maxLength'] = 8;
          response['placeholder'] = "XXXXXXXX";
          response['pattern'] = "(\\d){8}";
          break;
        case 'RUC':
          response['minLength'] = 11;
          response['maxLength'] = 11;
          response['placeholder'] = "XXXXXXXXXXX";
          response['pattern'] = "(\\d){11}";
          break;
        case 'CE':
          response['minLength'] = 9;
          response['maxLength'] = 9;
          response['placeholder'] = "XXXXXXXXX";
          response['pattern'] = "(\\d){9}";
          break;
        default:
          response = null;
          break;
      }

      return response;
    case 'CL':
      switch (documentType) {
        case 'RUT':
          response['minLength'] = 9;
          response['maxLength'] = 10;
          response['placeholder'] = "XXXXXXXX-Y";
          response['pattern'] = "((\\d){7,8}-)+([kK]{1})";
          break;
        default:
          response = null;
          break;
      }

      return response;
      // case 'CO':
      //     switch (documentType) {
      //         case 'RUT':
      //             response['minLength'] = 10;
      //             response['maxLength'] = 10;
      //             response['pattern'] = "(\\d){10}";
      //             break;
      //         default:
      //             response = null;
      //             break;
      //     }
      //
      //     return response;
    default:
      return null;
  }
};

export const getCurrencySymbol = (country: string) => {
  if (country === 'PE')
    return 'S/';
  return '$';
};

export const getCurrencyFormat = (value: number) => value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

export const getCountryName = (isoCode: string) => {
  let countryName = '';
  AVAILABLES_COUNTRIES.forEach(({ iso, name, extra }) => {
    if (iso === isoCode || isoCode === extra)
      countryName = name.toLocaleLowerCase().replaceAll(' ', '_');
  });

  return countryName;
};

export const getPhone = (setPhoneValidation: any, language?: string): string => {
  let indicative = '+56';
  const lan = language ? language : i18n.language;
  switch (lan) {
    case 'es_PE':
      indicative = '+51';
      setPhoneValidation({
        indicative: 51,
        size: 9,
      });
      break;
    case 'es_CO':
      indicative = '+57';
      setPhoneValidation({
        indicative: 57,
        size: 10,
      });
      break;
    case 'es_MX':
      indicative = '+52';
      setPhoneValidation({
        indicative: 52,
        size: 10,
      });
      break;
    case 'es_US':
    case 'en_US':
      indicative = '+1';
      setPhoneValidation({
        indicative: 1,
        size: 10,
      });
      break;
    case 'es_CL':
    default:
      indicative = '+56';
      setPhoneValidation({
        indicative: 56,
        size: 9,
      });
      break;
  }

  return indicative;
}

export const getCountryCode = (isoCode: string, returnIso3166: boolean = false): string => {
  let countryCode = '';

  AVAILABLES_COUNTRIES.forEach(({ iso, extra }) => {
    if (iso === isoCode) {
      countryCode = iso.split('_')[1];
    }
    else if (isoCode === extra) {
      countryCode = extra.split('_')[1];
    }
  });

  if(returnIso3166) {
    switch (countryCode.toUpperCase()) {
      case 'CL':
        return 'CHL';
      case 'CO':
        return 'COL';
      case 'US':
        return 'USA'
      case 'MX':
        return 'MEX';
      case 'PE':
        return 'PER';
      default:
        throw `Country not found (Value: ${countryCode}).`;
    }
  }

  return countryCode;
};

export const getSignUpFieldsByCountry = (country: string) : SignUpFields => {

  switch (country) {
    case 'CL_FIELDS':
        return {
          name: '',
          lastname: '',
          email: '',
          phone: '',
          state: '',
          cityId: '',
          terms_conditions: false,
        };
    case 'CO_FIELDS':
      return {
        name: '',
        lastname: '',
        email: '',
        phone: '',
        state: '',
        cityId: '',
        terms_conditions: false,
      };
    case 'PE_FIELDS':
      return {
        name: '',
        lastname: '',
        email: '',
        phone: '',
        state: '',
        cityId: '',
        terms_conditions: false,
        ruc: false,
        permissions_data: false,
      };
    case 'US_FIELDS':
      return {
        name: '',
        lastname: '',
        email: '',
        phone: '',
        state: '',
        cityId: '',
        terms_conditions: false,
      };
    default:
      return {
        name: '',
        lastname: '',
        email: '',
        phone: '',
        state: '',
        cityId: '',
        terms_conditions: false,
      };
  }
}

export const getAppUrl = (complete: boolean = false) => {
  const findCurrentURL = window.location;

  if(complete)
  {
    return findCurrentURL.protocol + "//" + findCurrentURL.host + "/" + findCurrentURL.pathname.split('/')[1];
  }

  return findCurrentURL.protocol + "//" + findCurrentURL.host;
}

//TODO: por borrar
export const formattingAxiosResponseErrors = (err: any | AxiosError): ApiResponse => {
  if(axios.isAxiosError(err))
  {
    if(!err.response)
    {
      console.error(err);
      return  { success: false, typeError: 'somethingWentWrong', errorPayload: err, severity: Severity.ERROR };
    }else if(err.response?.status === 500)
    {
      console.error(err, err.response?.data);
      return  { success: false, typeError: 'somethingWentWrong', errorPayload: err.response?.data, severity: Severity.ERROR };
    }else{
      let type = (err.response?.data?.type || '').replaceAll('.', '');

      return  { success: false, error: err.response?.data?.message || 'Something went wrong', typeError: type, errorPayload: err.response?.data, severity: Severity.WARNING };
    }
  }
  return  { success: false, typeError: 'somethingWentWrong', errorPayload: err, severity: Severity.ERROR };
};

export const formattingTitaskBffResponseErrors = (err: any | AxiosError, ignoreStatuses: number[] = []): BffApiResponse => {
  if(axios.isAxiosError(err))
  {
    if(!err.response)
    {
      console.error('Error with wrong structure (Axios)', err);
      return  new BffApiResponse(false, null, 500, 'Something went wrong. (code: 101)');
    }else if(err.response.data?.data){

      if(ignoreStatuses.length > 0 && ignoreStatuses.includes(err.response.status))
      {
        return  new BffApiResponse(true, null);
      }

      if (err.response.status === 401) {
        if (err.response.data.message !== 'AuthDao - loginUser error') window.location.assign(window.location.hostname);
        return  new BffApiResponse(false, null, err.response.status, err.response.data.data.message || 'Something went wrong. (code: 202)');
      }
      return  new BffApiResponse(false, null, err.response.status, err.response.data.data.message || 'Something went wrong. (code: 202)');
    }else{
      console.error('Error with wrong structure (Backend)', err);
      return  new BffApiResponse(false, null, err.response.status, 'Something went wrong. (code: 201)');
    }
  }
  console.error('Error with wrong structure (Frontend)', err);
  return  new BffApiResponse(false, null, 500, 'Something went wrong. (code: 301)');
};

export const formattingAxiosResponseErrorsFromIntegrationsApi = (err: any | AxiosError): BffApiResponse => {
  if(axios.isAxiosError(err))
  {
    if(!err.response)
    {
      return  new BffApiResponse(false, null, 500, 'Something went wrong. (code: 101)');
    }
    else if(err.response?.status === 404) {
      console.log("404 =>", err.response?.data?.message?.message);
      return  new BffApiResponse(true, null);
    }
    else if(err.response?.status === 500)
    {
      return  new BffApiResponse(false, null, 500, 'Something went wrong. (code: 102)');
    }
    else{
      const message = (err.response?.data?.message?.message || '').replaceAll('.', '');

      return  new BffApiResponse(false, null, err.response.status, message || 'Something went wrong. (code: 202)');
    }
  }

  return  new BffApiResponse(false, null, 500, 'Something went wrong. (code: 301)');
};

export const formattingErrorResponseToAlertBody = (apiResponse: ApiResponse): AlertMessage  => {
    return {
      open: true,
      message: ((apiResponse.typeError ? (apiResponse.typeError) : apiResponse.error) || ''),
      translate: (!!apiResponse.typeError),
      severity: (apiResponse.severity || Severity.WARNING)
    };
};

export const getLogoS3Key = (files : CertificateFilePresenterInput[]) : string | null => {
  const logo = files.filter((file: CertificateFilePresenterInput) => {
    return file.type === 'LOGO';
  });

  return (logo.length > 0 ? logo[(logo.length - 1)].key : null);
}

export const getAvatarS3Key = (files : CertificateFilePresenterInput[]) : string | null => {
  const logo = files.filter((file: CertificateFilePresenterInput) => {
    return file.type === 'AVATAR';
  });
  return (logo.length > 0 ? logo[(logo.length - 1)].key : null);
}

export const getDescendantProp = (obj: any, desc: string): any => {
  let arr = desc.split(".");
  while(arr.length && (obj = obj[arr.shift() || '']));
  return obj;
}


export const getTurnPrice = (priceList: any, t: any) => {
  return priceList.map((price: string, index: number) => {
    let priceValue = '', priceType = '';
    if (price.split("/").length > 2) {
        const priceSplitted = price.split(" ");
        priceValue = priceSplitted[0];
        priceType = priceSplitted[1];
    } else {
        const priceSplitted = price.split("/");
        priceValue = priceSplitted[0];
        priceType = priceSplitted[1];
    }
    priceValue = priceValue.replaceAll('p','');
    const priceTypeSplitted = priceType.replaceAll('.','').split("/");
    priceType = priceTypeSplitted.length > 1 ? priceTypeSplitted[1] : priceTypeSplitted[0];
    const priceTypeTranslation = priceType ? t('scenes.available_tasks_days.components.available_tasks_card.' + (priceType)) : '';
    if(index === 0) {
        return <p key={index} className="task_card__item__info__price">{`${priceValue} p/${priceTypeTranslation}`}</p>
    }
    return <p key={index} className="task_card__item__info__price">{`${priceValue} p/${priceTypeTranslation}`}</p>
  });
};

export const sleep = (ms: number) : Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const isExpired = (expiredAt: Date) => {
  const now = new Date();
  return now.getTime() > expiredAt.getTime();
};
