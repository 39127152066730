import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from "redux-react-hook";
import AppBar from '@material-ui/core/AppBar';
import { Container, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Toolbar from '@material-ui/core/Toolbar';
import UserAction from '../../../redux/reducers/user/actions';
import LogoPng from '../../../res/img/logo.png';
import { capitalize } from '@mui/material';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AuthService from "../../../services/AuthService";
import message from '../../../res/img/notifications.svg';
import vector from '../../../res/img/Vector.svg';
import './Navbar.scss';

interface ItemsListInterface {
  text: string;
  icon?: any;
  path: string;
};

interface NavbarInferface {
  withIcon?: boolean;
};

export const Navbar = ({
  withIcon = true,
}: NavbarInferface) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const authUser = useSelector((state: any) => state.user);
  const [avatar, setAvatar] = useState('https://ui-avatars.com/api/?name=guest');
  const dispatch = useDispatch();
  const navigator = useHistory();
  const { t } = useTranslation();

  const init = async () => {
    if(authUser.id !== 0) {
      if (authUser.avatarBase64Encoded) {
        const avatarHrefBase64Encoded = await AuthService.getFileByKey(authUser.avatarBase64Encoded);
        setAvatar(`data:image/jpeg;charset=utf-8;base64,${avatarHrefBase64Encoded}`);
      }
      else {
        setAvatar(`https://ui-avatars.com/api/?name=${authUser.profile.name}`);
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  const toggleDrawer: any = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(open);
  };

  const logOut = async () => {
    const logOutResponse = await AuthService.logout();
    dispatch(UserAction.remove({}));

    if (logOutResponse.success) {
      navigator.push('/sign-in');
    }
  };
  const notifications =async ()=>{
    navigator.push('/notifications');
  }

  const getListMenuItems = (): ItemsListInterface[] => {
    let menuItems: ItemsListInterface[] = [
      { text: 'components.navbar.menu.home', icon: 'files/icons/home.svg', path: 'home' },
      { text: 'components.navbar.menu.my_contract', icon: 'files/icons/my_contract.svg', path: 'my-contract' },
      { text: 'components.navbar.menu.profile', icon: 'files/icons/profile.svg', path: 'profile' },
      { text: 'components.navbar.menu.certifications', icon: 'files/icons/certifications.svg', path: 'certifications' },
      { text: 'components.navbar.menu.available_tasks', icon: 'files/icons/available_tasks.svg', path: 'available-tasks' },
      { text: 'components.navbar.menu.my_tasks', icon: 'files/icons/my_tasks.svg', path: 'my-tasks' },
      { text: 'components.navbar.menu.my_wallet', icon: 'files/icons/my_wallet.svg', path: 'my-payments' },
      // { text: 'components.navbar.menu.news', icon: 'files/icons/news.svg', path: 'news' },
      // { text: 'components.navbar.menu.bonus', icon: 'files/icons/bonus.svg', path: 'bonus' },
      { text: 'components.navbar.menu.contact', icon: 'files/icons/contact.svg', path: 'contact' },
      { text: 'components.navbar.menu.message', icon: 'files/icons/inbox.svg', path: 'message' }
    ];

    let excludedItems: string[] = [];

    // switch (authUser.countryCode) {
    //   default:
    //     excludedItems.push('components.navbar.menu.bonus');
    //     break;
    // }

    return menuItems.filter((item: ItemsListInterface): boolean => !excludedItems.includes(item.text));
  };

  const ListItems = () => {
    let items: ItemsListInterface[] = getListMenuItems();

    return (
      <div
        className='top'
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List className="list-main">
          {items.map((item, index) => (
            <Link key={index} to={`/${item.path}`}>
              <ListItem className="list-main__item" button>
                {withIcon && (
                  <ListItemIcon className="list-main__item--icon">
                    <img src={`https://titaskdomain.s3.us-east-2.amazonaws.com/${item.icon}`} alt="icon" />
                  </ListItemIcon>
                )}
                <ListItemText className="list-main__item--text" primary={t(item.text)} />
                {item.path=='message'?(<div className='div-message'>{authUser.countMessage}</div>):<></>}
              </ListItem>
            </Link>
          ))}
        </List>
      </div>
    );
  };
  return (
    <div className="toolbar__main">
      <AppBar position="static">
        <Container maxWidth="sm">
          <Toolbar className="toolbar__main__content">
            <Link to="/home">
              <img className="toolbar__main__content__img" src={LogoPng} alt="logo" />
            </Link>
            <div className='icons_navbar'>
              {(authUser.id !== 0) && (

                <><IconButton
                  edge="start"
                  className="toolbar__main__content__icon"
                  color="inherit"
                  aria-label="menu"
                  onClick={notifications}
                >
                  {authUser.countMessage > 0 ? (<img className="icon_space" src={message} />) : (<img className="icon_space" src={vector} />)}


                </IconButton><IconButton
                  edge="start"
                  className="toolbar__main__content__icon"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                >
                    <MenuIcon />
                  </IconButton></>
              )}
            </div>
            {(authUser.id !== 0) && (
                <Drawer
                    anchor='left'
                    className="drawer__main"
                    open={openDrawer}
                    onClose={toggleDrawer(false)}
                >
                  <div className="header_drawer">
                    <div className="header_drawer__profile">
                      <div className="header_drawer__profile-content">
                        <img
                            src={avatar}
                            className="header_drawer__profile-content--img"
                            alt="avatar"
                        />
                        <p className="header_drawer__profile-content--text">{capitalize(t('components.navbar.hello'))}, <strong>{authUser.profile.name && capitalize(authUser.profile.name)}</strong></p>
                      </div>
                      <CloseIcon className="header_drawer__close" onClick={toggleDrawer(false)} />
                    </div>
                  </div>
                  <div className="menu_items__container">
                    {<ListItems />}
                  </div>
                  <div className="footer_drawer" onClick={logOut}>
                    <PowerSettingsNewIcon />
                    <span className="footer_drawer__text">{t('components.navbar.logout')}</span>
                  </div>
                </Drawer>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
